import React from 'react';

const Button = () => {
  return (
    <div>
      button
    </div>
  );
};

export default Button;