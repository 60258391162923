import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Button from "./Button";
import axios from "axios";


const HelloWorld = (props) => {
  const [id, setId] = useState(null)

  const search = () => {
    axios.get('test/get_id')
    .then((response) => {
      setId(response.data);
    });
  }

  return (
    <div>
      <button className="button small primary" onClick={() => search()}>
        Fetch id
      </button>
      <span>
        {id}
      </span>
    </div>
  )
}

HelloWorld.propTypes = {
  greeting: PropTypes.string
};
export default HelloWorld
