import React from "react";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";

const QBInput = ({ rule, changeRuleValue, fieldOptions }) => {
  if (rule.requiresValue !== true) {
    return null;
  }
  return (
    <div className="rule-value-container">
      {rule.type === "text" && (
        <TextInput
          rule={rule}
          changeRuleValue={changeRuleValue}
          fieldOptions={fieldOptions}
        />
      )}
      {rule.type === "date" && (
        <DateInput rule={rule} changeRuleValue={changeRuleValue} />
      )}
    </div>
  );
};

const TextInput = ({ rule, changeRuleValue, fieldOptions }) => {
  const options =
    fieldOptions[rule.option]?.sort().map((fo) => ({ value: fo, label: fo })) ||
    [];
  return (
    <CreatableSelect
      options={options}
      createOptionPosition="first"
      onChange={(val) => {
        changeRuleValue(
          rule.id,
          val
            .map((v) => ({
              label: v.label.replace(/,/g, ""),
              value: v.value.replace(/,/g, ""),
            }))
            .filter((v) => v.value !== "")
        );
      }}
      isMulti={true}
      value={rule.value}
      styles={{
        container: (baseStyles) => ({ ...baseStyles, height: 39 }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          height: 39,
          padding: "0 6px",
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          margin: "0px",
        }),
        indicatorsContainer: (baseStyles, state) => ({
          ...baseStyles,
          height: 39,
        }),
      }}
    />
  );
};

const DateInput = ({ rule, changeRuleValue }) => {
  return (
    <input
      type="date"
      value={rule.value}
      onChange={(e) => changeRuleValue(rule.id, e.target.value)}
    />
  );
};

QBInput.propTypes = {
  rule: PropTypes.object.isRequired,
  changeRuleValue: PropTypes.func.isRequired,
};

export default QBInput;
