import React from "react";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useActivityTagModal } from "./ActivityTagModalContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../../components/ErrorMessage/ErrorMessage";

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  category: yup.string().required(),
  group: yup.string().required(),
  detail: yup.string(),
  exclude: yup.boolean(),
});

const ActivityTagForm = ({ submit }) => {
  const { activityTag } = useActivityTagModal();
  const tags =
    activityTag?.tags === ""
      ? []
      : activityTag?.tags
          ?.split(",")
          .map((tag) => ({ value: tag, label: tag }));
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: activityTag?.name || "",
      type: activityTag?.type || "",
      category: activityTag?.category || "",
      group: activityTag?.group || "",
      detail: activityTag?.detail || "",
      tags,
      exclude: activityTag?.exclude || false,
    },
    resolver: yupResolver(schema),
  });

  const localSubmit = (data) => {
    submit(data);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <label htmlFor="activity_tags_nam">NAME</label>
      <input
        id="activity_tags_name"
        name="name"
        type="text"
        style={{ marginBottom: 4 }}
        {...register("name")}
      />
      <ErrorMessage errors={errors} name={"name"} />
      <label htmlFor="activity_tags_type">TYPE</label>
      <select
        id="activity_tags_type"
        name="type"
        style={{ marginBottom: 0 }}
        {...register("type")}
      >
        <option value="">map to existing...</option>
        <option value="Meeting Scheduled">Meeting Scheduled</option>
        <option value="Meeting Attended">Meeting Attended</option>
        <option value="Meeting Completed">Meeting Completed</option>
        <option value="Call Made">Call Made</option>
        <option value="Email Sent">Email Sent</option>
      </select>
      <ErrorMessage errors={errors} name={"type"} />
      <label htmlFor="activity_tags_category">CATEGORY</label>
      <select
        name="category"
        id="activity_tags_category"
        style={{ marginBottom: 0 }}
        {...register("category")}
      >
        <option value="">map to existing...</option>
        <option value="Call">Call</option>
        <option value="Email">Email</option>
        <option value="Messaging">Messaging</option>
        <option value="Social Media">Social Media</option>
      </select>
      <ErrorMessage errors={errors} name={"category"} />
      <label htmlFor="activity_tags_group">GROUP</label>
      <select
        id="activity_tags_group"
        name="group"
        {...register("group")}
        style={{ marginBottom: 0 }}
      >
        <option value="">map to existing...</option>
        <option value="Sales">Sales</option>
        <option value="Marketing">Marketing</option>
      </select>
      <ErrorMessage errors={errors} name={"group"} />
      <label htmlFor="activity_tags_detail">DETAIL</label>
      <select
        name="detail"
        id="activity_tags_detail"
        {...register("detail")}
        style={{ marginBottom: 0 }}
      >
        <option value="">map to existing...</option>
        <option value="Call Answered">Call Answered</option>
        <option value="Call Left Voicemail">Call Left Voicemail</option>
        <option value="Call No Voicemail">Call No Voicemail</option>
        <option value="Call No Interest">Call No Interest</option>
        <option value="Email Sent">Email Sent</option>
        <option value="Email Open">Email Open</option>
        <option value="Email Click">Email Click</option>
        <option value="Email Bounce">Email Bounce</option>
        <option value="Email Reply">Email Reply</option>
        <option value="Linkedin InMail Sent">Linkedin InMail Sent</option>
        <option value="Linkedin Connection Sent">
          Linkedin Connection Sent
        </option>
        <option value="Linkedin Connection Accepted">
          Linkedin Connection Accepted
        </option>
      </select>
      <ErrorMessage errors={errors} name={"detail"} />

      <Controller
        control={control}
        name="tags"
        render={({ field: { onChange, value, name, ref } }) => {
          return (
            <CreatableSelect
              options={[]}
              onChange={(val) => {
                onChange(
                  val
                    .map((v) => ({
                      label: v.label.replace(/,/g, ""),
                      value: v.value.replace(/,/g, ""),
                    }))
                    .filter((v) => v.value !== "")
                );
              }}
              isMulti={true}
              value={value}
              name={name}
              inputRef={ref}
            />
          );
        }}
      />

      <div>
        <input
          type="checkbox"
          id="exclude_tag"
          {...register("exclude")}
          style={{ marginTop: 15 }}
        />
        <label htmlFor="exclude_tag">Exclude tagged items from screens</label>
      </div>

      <input
        type="submit"
        onClick={handleSubmit(localSubmit)}
        className="button update-account"
        value="Tag Activity"
      />
    </form>
  );
};

export default ActivityTagForm;
