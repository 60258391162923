import axios from "axios";

const optionsFetcherService = {
  getOptions: async ({ mode, field, type }) => {
    try {
      const response = await axios.get("/traffic/filter_values", {
        params: {
          setting: {
            mode: mode,
            field: field,
            type: type,
          },
        },
      });
      return response.data.map((option) => option.value);
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export default optionsFetcherService;
