import React from "react";
import { useActivityTagModal } from "./ActivityTagModalContext";

const NewActivityTagButton = () => {
  const { openModal } = useActivityTagModal();
  return (
    <div id="activity_tags_list">
      <div className="container">
        <div className="row align-center">
          <button className="button tiny" onClick={openModal}>
            + New activity tagging
          </button>
        </div>
      </div>
      <p></p>
    </div>
  );
};

export default NewActivityTagButton;
