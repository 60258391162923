import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useActivityTagModal } from "./ActivityTagModalContext";
import SalesTouchesTable from "./SalesTouchesTable";
import ActivityTagForm from "./ActivityTagForm";
import QueryBuilder from "../../../../components/QueryBuilder/QueryBuilder";
import queryBuilderService from "../../../../services/QueryBuilderService";
import { useActivityTagging } from "./ActivityTaggingContext";
import { options } from "./activityTagOptions";

const ActivityTagModal = () => {
  const { fieldOptions } = useActivityTagging();

  const {
    submitForm,
    isModalOpen,
    closeModal,
    refreshSalesTouches,
    salesTouches,
    isLoading,
    changePage,
    pagination,
    setCurrentQuery,
    activityTag,
  } = useActivityTagModal();

  const submit = ({ category, detail, group, name, type, tags, exclude }) => {
    submitForm({
      category,
      detail,
      group,
      name,
      type,
      exclude,
      tags: tags?.map((tag) => tag.value).join(",") || "",
    });
  };

  const handleQueryBuilderChange = (query) => {
    setCurrentQuery(queryBuilderService.filterValid(query));
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="lg">
      <DialogContent style={{ padding: 50 }}>
        <button
          className="close-button"
          aria-label="Close modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div id="filter" className="row expanded">
          <div className="columns">
            <div className="builder-basic builder-basic-activity-tagging">
              <QueryBuilder
                options={options}
                onQueryChange={handleQueryBuilderChange}
                fieldOptions={fieldOptions}
                defaultQuery={
                  activityTag?.["rules_params"]
                    ? queryBuilderService.convertFromRulesParams(
                        activityTag["rules_params"].rules,
                        options
                      )
                    : null
                }
              />
            </div>
          </div>
          <div id="sales-touches-preview-control">
            <button
              onClick={() => refreshSalesTouches()}
              className="button small primary"
            >
              <i
                className={
                  "icon icon-refresh" + (isLoading ? " icon-spin" : "")
                }
              ></i>
            </button>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="row expanded">
            <div className="col-sm activity-tagging-form">
              <ActivityTagForm submit={submit} />
            </div>
            <div className="columns sales_touches_list">
              <div
                id="sales_touches"
                className="text-center"
                style={{ width: "100%" }}
              >
                <SalesTouchesTable
                  salesTouches={salesTouches}
                  isLoading={isLoading}
                  changePage={changePage}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityTagModal;
