import React, { createContext, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useActivityTagging } from "./ActivityTaggingContext";
import queryBuilderService from "../../../../services/QueryBuilderService";
import { options } from "./activityTagOptions";

const ActivityTagModalContext = createContext(null);

const ActivityTagModalProvider = ({ children, id, activityTag }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [salesTouches, setSalesTouches] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuery, setCurrentQuery] = useState(
    activityTag?.["rules_params"]?.rules
      ? queryBuilderService.convertFromRulesParams(
          activityTag["rules_params"].rules,
          options
        )
      : null
  );

  const activityTaggingListSalesTouchesPath = React.useMemo(
    () => "/sales_touches/activity_tagging_list"
  );
  const isEditing = React.useMemo(() => !!id);
  const activityTagsPath = React.useMemo(() => "/activity_tags", []);

  const refreshSalesTouches = useCallback(
    (page = null) => {
      setIsLoading(true);
      const currentPage = page === null ? pagination?.currentPage : page;

      const searchParams = currentQuery
        ? queryBuilderService.convertToRulesParams(currentQuery)
        : null;
      axios
        .get(activityTaggingListSalesTouchesPath, {
          params: {
            page: currentPage,
            search_params: searchParams ? JSON.stringify(searchParams) : null,
          },
        })
        .then((response) => {
          const totalPages = Math.ceil(
            response.data["sales_touches_count"] / response.data["page_size"]
          );
          setPagination({
            currentPage: response.data["current_page"],
            pageSize: response.data["page_size"],
            salesTouchesCount: response.data["sales_touches_count"],
            totalSalesTouchesCount: response.data["total_sales_touches_count"],
            totalPages,
          });
          setSalesTouches(response.data["sales_touches"]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [currentQuery, pagination]
  );

  const { refreshActivityTags } = useActivityTagging();

  const deleteActivityTag = useCallback(() => {
    setIsUpdating(true);
    const url = `${activityTagsPath}/${id}.json`;
    axios
      .delete(url)
      .then(() => {
        refreshActivityTags();
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, [id]);

  const changePage = (direction) => {
    refreshSalesTouches(pagination.currentPage + direction);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen || !isEditing) {
      refreshSalesTouches();
    }
  }, [isModalOpen]);

  const submitForm = (data) => {
    const url = isEditing
      ? `${activityTagsPath}/${id}.json`
      : `${activityTagsPath}.json`;
    const method = isEditing ? "put" : "post";

    const rulesParams = currentQuery
      ? queryBuilderService.convertToRulesParams(currentQuery)
      : null;

    axios({
      method,
      url,
      data: {
        activity_tag: {
          ...data,
          rules_params: rulesParams ? JSON.stringify(rulesParams) : null,
        },
      },
    }).then(() => {
      refreshActivityTags();
      closeModal();
    });
  };

  return (
    <ActivityTagModalContext.Provider
      value={{
        id,
        refreshSalesTouches,
        salesTouches,
        isLoading,
        pagination,
        changePage,
        submitForm,
        isUpdating,
        deleteActivityTag,
        isModalOpen,
        openModal,
        closeModal,
        activityTag,
        currentQuery,
        setCurrentQuery,
      }}
    >
      {children}
    </ActivityTagModalContext.Provider>
  );
};

const useActivityTagModal = () => {
  const context = React.useContext(ActivityTagModalContext);
  if (context === undefined) {
    throw new Error(
      "useActivityTagModal must be used within a ActivityTaggingProvider"
    );
  }
  return context;
};

export { useActivityTagModal };
export default ActivityTagModalProvider;
