import React, { useEffect } from "react";
import { useActivityTagging } from "./ActivityTaggingContext";
import axios from "axios";
import Loading from "../../../../components/Loading/Loading";
import ActivityTagModalProvider, {
  useActivityTagModal,
} from "./ActivityTagModalContext";
import ActivityTagModal from "./ActivityTagModal";
import { Tooltip } from "@mui/material";

const ActivityTagsTable = () => {
  const { activityTags, loadingActivityTags } = useActivityTagging();
  if (loadingActivityTags) {
    return (
      <div style={{ height: 80 }}>
        <Loading />
      </div>
    );
  }

  return (
    <div className="text-center">
      <table id="activity-tags-table" className="rtable basic text-left">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Detail</th>
            <th>Count</th>
            <th>Job Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {activityTags.map((activityTag) => (
            <ActivityTagModalProvider
              key={activityTag.id}
              id={activityTag.id}
              activityTag={activityTag}
            >
              <ActivityTagModal />
              <tr>
                <td>{activityTag.name}</td>
                <td>{activityTag.type}</td>
                <td>{activityTag.detail}</td>
                <td>
                  <CountColumn id={activityTag.id} />
                </td>
                <td className="text-center">
                  <JobStatus
                    id={activityTag.id}
                    jobStatus={activityTag["job_status"]}
                  />
                </td>
                <td className="text-center">
                  <ActivityTagActions />
                </td>
              </tr>
            </ActivityTagModalProvider>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const JobStatus = ({ id, jobStatus }) => {
  const { autoRefreshActivityTagging } = useActivityTagging();
  useEffect(() => {
    if (jobStatus === "Ongoing") {
      autoRefreshActivityTagging(id);
    }
  }, [jobStatus]);
  if (jobStatus === null || jobStatus === "Complete") {
    return (
      <Tooltip title={"Complete"}>
        <i className="icon icon-check icon-green" />
      </Tooltip>
    );
  }
  if (jobStatus === "Error") {
    return (
      <Tooltip title={"Error"}>
        <i className="icon icon-warning icon-red" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={"In Progress"}>
      <i className="icon icon-spin icon-spinner icon-yellow" />
    </Tooltip>
  );
};

const ActivityTagActions = () => {
  const { openModal, deleteActivityTag, isUpdating } = useActivityTagModal();
  const handleDelete = () => {
    const confirmed = confirm(
      "Are you sure you want to delete this activity tag?"
    );
    if (confirmed) {
      deleteActivityTag();
    }
  };

  if (isUpdating) {
    return <Loading variant="compact" />;
  }

  return (
    <>
      <a onClick={openModal}>
        <i className="icon icon-edit-btm" />
      </a>
      <a style={{ marginLeft: 4 }} onClick={handleDelete}>
        <i className="icon icon-trash-o" />
      </a>
    </>
  );
};

const CountColumn = ({ id }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [count, setCount] = React.useState(null);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/activity_tags/" + id + "/count")
      .then((response) => {
        setCount(response.data.count);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading variant="compact" />;
  }

  return <span>{count?.toLocaleString("en-US")}</span>;
};

export default ActivityTagsTable;
