import React from "react";
import ActivityTaggingContext from "./ActivityTaggingContext";
import NewActivityTagButton from "./NewActivityTagButton";
import ActivityTagModalProvider from "./ActivityTagModalContext";
import ActivityTagModal from "./ActivityTagModal";
import ActivityTagsTable from "./ActivityTagsTable";

const ActivityTagging = ({
  activityTaggingListSalesTouchesPath,
  teamEditListActivityTagsPath,
  activityTagsPath,
}) => {
  return (
    <ActivityTaggingContext
      activityTaggingListSalesTouchesPath={activityTaggingListSalesTouchesPath}
      teamEditListActivityTagsPath={teamEditListActivityTagsPath}
      activityTagsPath={activityTagsPath}
    >
      <h4>Activity Tagging</h4>
      <ActivityTagModalProvider
        activityTaggingListSalesTouchesPath={
          activityTaggingListSalesTouchesPath
        }
        activityTagsPath={activityTagsPath}
      >
        <NewActivityTagButton />
        <ActivityTagModal />
      </ActivityTagModalProvider>
      <ActivityTagsTable />
    </ActivityTaggingContext>
  );
};

export default ActivityTagging;
