const defaultOperators = [
  { value: "equals", label: "Equals", requiresValue: true },
  { value: "does_not_equal", label: "Does Not Equals", requiresValue: true },
  { value: "contains", label: "Contains", requiresValue: true },
  { value: "does_not_contain", label: "Does Not Contain", requiresValue: true },
  { value: "is_empty", label: "Is Empty", requiresValue: false },
  { value: "is_not_empty", label: "Is Not Empty", requiresValue: false },
];

const options = [
  {
    name: "subject",
    label: "Subject",
    type: "text",
    operators: defaultOperators,
  },
  {
    name: "task_type",
    label: "Task Type",
    type: "text",
    operators: defaultOperators,
  },
  {
    name: "task_subtype",
    label: "Task Subtype",
    type: "text",
    operators: defaultOperators,
  },
  {
    name: "created_by",
    label: "Created By",
    type: "text",
    operators: defaultOperators,
  },
  {
    name: "created_date",
    label: "Created Date",
    type: "date",
    operators: [
      { value: "equals", label: "Equals", requiresValue: true },
      { value: "less_or_equal", label: "Less or equal", requiresValue: true },
      {
        value: "greater_or_equal",
        label: "Greater or equal",
        requiresValue: true,
      },
    ],
  },
];

export { options };
