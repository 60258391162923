import React from "react";

const ErrorMessage = ({ errors, name }) => {
  return (
    <p style={{ color: "red", marginBottom: "1rem" }}>
      {errors[name]?.message}
    </p>
  );
};

export default ErrorMessage;
