import React from "react";
import PropTypes from "prop-types";

const Loading = ({ variant = "shuffle", display = true, ...props }) => {
  if (!display) {
    return null;
  }
  if (variant === "shuffle") {
    return (
      <div
        className="shuffle-container"
        style={{ position: "relative", zIndex: 0, top: 35, left: 0 }}
      >
        <div className="holder">
          <div className="box"></div>
        </div>
        <div className="holder">
          <div className="box"></div>
        </div>
        <div className="holder">
          <div className="box"></div>
        </div>
      </div>
    );
  } else if (variant === "bubble") {
    return <div className="loader-side" style={{ position: "relative" }}></div>;
  } else if (variant === "compact") {
    return (
      <div className="cssload-square compact" {...props}>
        <div className="cssload-square-part cssload-square-green"></div>
        <div className="cssload-square-part cssload-square-pink"></div>
        <div className="cssload-square-blend"></div>
      </div>
    );
  }
  throw new Error("Invalid variant");
};

Loading.propTypes = {
  variant: PropTypes.oneOf(["shuffle", "bubble", "compact"]),
};

export default Loading;
