import React from "react";
import Loading from "../../../../components/Loading/Loading";

const SalesTouchesTable = ({
  pagination,
  salesTouches,
  isLoading,
  changePage,
}) => {
  if (isLoading) {
    return <Loading variant="shuffle" />;
  }
  const firstItem =
    pagination?.salesTouchesCount > 0
      ? pagination?.currentPage * pagination?.pageSize + 1
      : 0;

  const lastItem = Math.min(
    firstItem + pagination?.pageSize - 1,
    pagination?.salesTouchesCount
  );

  return (
    <div>
      <table id="sales_touches-table" className="rtable basic text-left">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Person</th>
            <th>Type</th>
            <th>Task Subtype</th>
            <th>Created By</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {salesTouches.map((salesTouch) => (
            <tr key={salesTouch.id}>
              <td>{salesTouch.subject}</td>
              <td>
                <a href={"/people/detail/" + salesTouch.lorc_id}>
                  {salesTouch.full_name}
                </a>
              </td>
              <td>{salesTouch.task_type}</td>
              <td>{salesTouch["task_subtype"]}</td>
              <td>{salesTouch["created_by"]}</td>
              <td>{salesTouch["created_date"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="container" style={{ paddingBottom: 8 }}>
        <div className="row expanded">
          <div className="columns">
            <button
              className="button tiny primary"
              disabled={pagination?.currentPage === 0}
              onClick={() => changePage(-1)}
            >
              <i className="icon icon-chevron-left" />
            </button>
          </div>
          <div
            className="columns"
            style={{
              width: "100%",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <p>
              {firstItem} - {lastItem} of{" "}
              {pagination?.salesTouchesCount.toLocaleString("en-US")} (
              {pagination?.totalSalesTouchesCount.toLocaleString("en-US")} in
              total)
            </p>
          </div>
          <div className="columns">
            <button
              className="button tiny primary"
              disabled={pagination?.currentPage === pagination?.totalPages - 1}
              onClick={() => changePage(1)}
            >
              <i className="icon icon-chevron-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTouchesTable;
